import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { User } from '@core/models/user-profile.model';
import { LANGUAGES } from '@core/services/language/language.model';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { emailValidator, requireMatchValidator } from '@shared/validators/validators';
import { DeviceType } from '@site-management/model/site.model';
import { LegalEntity } from '@tenant/models/tenant.model';
import { UserForm } from '@user-management/models/user.model';
import { UserEndpointService } from '@user-management/services/user.endpoint.service';
import { UserService } from '@user-management/services/user.service';
import { CreateUser, GetUser, UpdateUser } from '@user-management/store/user.actions';
import { UserState } from '@user-management/store/user.state';
import { EMPTY, Observable, Subject, filter, map, switchMap, take, tap } from 'rxjs';
import { AutocompleteSelectionComponent } from '../../../../shared/components/autocomplete-selection/autocomplete-selection.component';
import { ContextualMenuComponent } from '../../../../shared/components/contextual-menu/contextual-menu.component';
import { UserRoleComponent } from '../../../../shared/components/user-role/user-role.component';
import { ClearInputDirective } from '../../../../shared/directives/clear-input/clear-input.directive';
import { InvalidControlScrollDirective } from '../../../../shared/directives/invalid-control-scroll/invalid-control-scroll';
import { LowercaseOnTypeDirective } from '../../../../shared/directives/lowercase-input/lowercase-input.directive';
import { ShowMatErrorDirective } from '../../../../shared/directives/show-mat-error/show-mat-error.directive';
import { SkeletonLoaderComponent } from '../../../../shared/modules/skeleton-loader/skeleton-loader.component';

@Component({
    selector: 'app-user-detail-page',
    templateUrl: './user-detail-page.component.html',
    styleUrls: ['./user-detail-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        MatCardModule,
        FormsModule,
        MatChipsModule,
        ReactiveFormsModule,
        ContextualMenuComponent,
        MatTooltipModule,
        MatMenuModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        ClearInputDirective,
        ShowMatErrorDirective,
        LowercaseOnTypeDirective,
        MatSelectModule,
        MatOptionModule,
        AutocompleteSelectionComponent,
        RouterLink,
        UserRoleComponent,
        MatButtonModule,
        InvalidControlScrollDirective,
        MatProgressSpinnerModule,
        SkeletonLoaderComponent,
        DatePipe,
        TranslateModule,
    ]
})
export class UserDetailPageComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  public _userEndPointService = inject(UserEndpointService);
  private route = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  private router = inject(Router);
  private store = inject(Store);
  private _cdr = inject(ChangeDetectorRef);
  private _userService = inject(UserService);

  user: User;
  form: UserForm;
  public DeviceType = DeviceType;
  public submitLoading = false;

  public owners: LegalEntity[];
  public loading = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  languages = LANGUAGES;
  @Input() creationMode = true;
  @Input() owner: string;
  @Input() id: string;
  @Input() openInDialog = false;
  @Output() closeDialogHandler?: EventEmitter<any> = new EventEmitter();
  @Input() isFromTenant: false;
  clearRoleSubject: Subject<void> = new Subject<void>();
  legalEntities$: Observable<LegalEntity[]> = inject(Store).select(AppState.legalEntitiesList);
  isUserAdmin$: Observable<boolean> = inject(Store).select(AppState.isUserAdmin);

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        tap((params) => !this.owner && !!params?.get('tenantId') && (this.owner = params?.get('tenantId'))),
        map((params) => (!!this.id ? this.id : !!params?.get('id') && params?.get('id'))),
        tap((id) => {
          this._cdr.markForCheck();
          !!id && this.store.dispatch(new GetUser(id));
          this.creationMode = !id;
          !id && this.createForm();
          this.loading = !this.creationMode;
          this._cdr.markForCheck();
        }),
        switchMap((id) => (!!id ? this.store.select(UserState.selectUser(id)) : EMPTY)),
        filter((user) => !!user),
      )
      .subscribe((user) => {
        this.user = user;
        this.createForm();
        this.loading = false;
        this._cdr.markForCheck();
      });
  }

  createForm() {
    this.form = null;
    this._cdr.detectChanges();

    const userLanguage: string = this.store.selectSnapshot<string>(AppState.language);

    this.form = this.formBuilder.group({
      id: [this.user?.id || ''],
      owner: [this.user?.owner || this.owner || '', [Validators.required]],
      firstName: [this.user?.firstName || '', Validators.required],
      lastName: [this.user?.lastName || '', Validators.required],
      email: [
        this.user?.email || '',
        {
          validators: [Validators.required, emailValidator()],
          asyncValidators: [this._userEndPointService.uniqueEmailValidator(this.user?.email)],
        },
      ],
      language: [this.user?.language || userLanguage, Validators.required],
      roles: [this.user?.roles || [], Validators.required],
    });
    this._cdr.markForCheck();

    this.store
      .select<LegalEntity[]>(AppState.legalEntitiesList)
      .pipe(
        filter((_) => !!_),
        take(1),
      )
      .subscribe((legalEntitiesList) => {
        this.owners = legalEntitiesList;

        // Set owner validator once owners are there
        this.isFromTenant && this.form.controls['owner'].disable();
        this.form?.get('owner').addValidators(requireMatchValidator(this.owners?.map((o) => o.id)));
        this._cdr.markForCheck();
      });
  }

  ownerSelected() {
    this.form?.get('roles').setValue([]);
    this.clearRoleSubject.next();
  }

  toggle2Fa() {
    this._userService.toggle2FA(this.user.id, this.user.mfaEnabled).subscribe();
  }

  sendPasswordEmail() {
    !!this.form?.get('email')?.value && this._userService.resetPassword(this.form.get('email').value, this.user.id);
  }

  deleteUser() {
    this._userService
      .deleteUser(this.user.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((_) => {
        this.router.navigate(['/users']);
      });
  }

  restoreUser() {
    this._userService.restoreUser(this.user);
    this.router.navigate(['/users']);
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.get('roles').updateValueAndValidity();
    if (this.form.valid) {
      const emailFormControl: FormControl = this.form.get('email') as FormControl;
      emailFormControl.setValue(emailFormControl.value.toLowerCase());
      this.submitLoading = true;
      if (this.creationMode) {
        this.store.dispatch(new CreateUser(this.form.getRawValue()));
        this.submitLoading = false;
        !this.openInDialog && this.router.navigate(['/users']);
      } else {
        this.store.dispatch(new UpdateUser(this.form.value));
        this.submitLoading = false;
      }
      this._cdr.markForCheck();

      !!this.openInDialog && this.closeDialogHandler.emit();
    }
  }
}
