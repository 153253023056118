import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { UserDetailPageComponent } from '../user-detail-page/user-detail-page.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-user-detail-dialog',
    templateUrl: './user-detail-dialog.component.html',
    styleUrls: ['./user-detail-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatButtonModule, MatDialogClose, MatIconModule, UserDetailPageComponent]
})
export class UserDetailDialogComponent {
  public id: string;
  public owner: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, public dialogRef: MatDialogRef<UserDetailDialogComponent>) {
    this.id = data.id;
    this.owner = data.owner;
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
