import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { UserProfileRole } from '@core/models/user-profile.model';
import { Store } from '@ngxs/store';
import { FetchRoles } from '@user-management/store/user.actions';
import { UserState } from '@user-management/store/user.state';
import { Observable, filter, map, startWith, take } from 'rxjs';
import { SortPipe } from '../../pipes/sort.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ShowMatErrorDirective } from '../../directives/show-mat-error/show-mat-error.directive';
import { MultiselectAutocompleteComponent } from '../multiselect-autocomplete/multiselect-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
@Component({
  selector: 'app-user-role',
  templateUrl: 'user-role.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UserRoleComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MultiselectAutocompleteComponent,
    FormsModule,
    ReactiveFormsModule,
    ShowMatErrorDirective,
    TranslateModule,
    SortPipe,
  ],
})
export class UserRoleComponent implements ControlValueAccessor, OnInit {
  @Input() public required;

  company: string;
  @Input() set ownerValue(ownerValue: string) {
    this.company = ownerValue;
    !!this.rolesMap && this.setRolesFilter();
    this._cdr.markForCheck();
  }

  @Input() formControlName: string;
  @Input() clearRoleEvent: Observable<void>;

  public control: AbstractControl;
  public isChipLoading: boolean = true;
  public allRoles: UserProfileRole[];
  private rolesMap: { [id: string]: UserProfileRole };
  public roleStandaloneControl = new FormControl();

  roleMap$: Observable<{ [id: string]: UserProfileRole }> = inject(Store).select(UserState.roles);
  private destroyRef = inject(DestroyRef);
  constructor(private store: Store, private controlContainer: ControlContainer, private _cdr: ChangeDetectorRef) {
    this.store.dispatch(new FetchRoles());
  }
  onTouched = () => {};
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this._cdr.markForCheck();
  }
  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {
    if (this.controlContainer && this.formControlName) {
      this.control = this.controlContainer.control.get(this.formControlName);
      this._cdr.detectChanges();
    }
    this.clearRoleEvent?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((_) => {
      this.control.setValue([]);
      this._cdr.markForCheck();
    });
    this.roleMap$
      .pipe(
        filter((_) => !!_),
        take(1)
      )
      .subscribe((rolesMap) => {
        this.rolesMap = rolesMap as { [id: string]: UserProfileRole };
        this.allRoles = Object.values(rolesMap);
        this.isChipLoading = false;
        this.setRolesFilter();
        this._cdr.markForCheck();
      });
  }

  private _filter(value): UserProfileRole[] {
    const instanceOfString = typeof value === 'string' || value instanceof String;
    const nameTerm = instanceOfString ? value.toLowerCase() : value.name.toLowerCase();
    const roleTerm = instanceOfString ? value.toLowerCase() : value.roleType.toLowerCase();

    return this.allRoles.filter(
      (role) => role.name.toLowerCase().includes(nameTerm) || role.roleType.toLowerCase().includes(roleTerm)
    );
  }

  setRolesFilter() {
    this.setRolesForOwner();
    this.roleStandaloneControl.valueChanges.pipe(
      startWith(''),
      map((role) => (role ? this._filter(role) : this.allRoles.slice())),
      take(1)
    );
  }

  setRolesForOwner() {
    const roles = Object.values(this.rolesMap);
    this.allRoles = !!this.company ? roles?.filter((role) => role.owner === this.company) : [];
    this._cdr.markForCheck();
    // this.control.setValue([]);
  }

  getTemplate = (item: UserProfileRole) => {
    return `${item.name} <small>(${item.roleType})</small>`;
  };
}
